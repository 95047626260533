.table > thead > tr > th {
    font-weight: 600 !important;
    text-transform: uppercase !important;
    color: #578ebe !important;
}

.table > tbody > tr > td {
    vertical-align: middle !important;
}

.btn-dt-default, .buttons-collection {
    background: #FFFFFF !important;
    color: #5e738b !important;
    border: 1px solid !important;
    text-transform: uppercase !important;
    font-weight: normal !important;
}

.btn-dt-default:hover, .buttons-collection:hover {
    color: #FFF !important;
    background-color: #495a6d !important;
    border-color: #495a6d !important;
}

.dataTables_filter label > i {
    position: absolute !important;
    left: 23px !important;
    top: 8px !important;
    opacity: 0.5 !important;
}

.dataTables_filter label > input {
    margin: 0px !important;
    text-indent: 17px !important;
}

.margin-top-30 {
    margin-top: 30px !important;
}

.table-container {
    margin-top: 20px !important;
}

.badge-warning {
    background-color: #1BBC9B !important;
}

.badge-info {
    background-color: #E08283 !important;
}

.pagination-panel-input {
    margin: 0px !important;
}

.dataTables_processing {
    background: #3C8DBC !important;
    border-radius: 10px !important;
    color: #FFFFFF !important;
    font-weight: bold;
    z-index: 10000;
    text-transform: uppercase;
}

.dataTables_processing img {
    display: none !important;
}

.dataTables_filter label > input:focus {
    width: 320px !important;
}

.dt-length-style {
    display: inline-block !important;
    border-right: 4px solid #EF9B9B !important;
    background-color: #F3F3F3 !important;
    padding: 3px !important;
    padding-left: 15px !important;
    padding-top: 7px !important;
    color: #1F64A0 !important;
    padding-bottom: 7px !important;
}

.dt-length-records {
    display: inline-block !important;
    padding: 3px !important;
    padding-left: 15px !important;
    color: #1F64A0 !important;
}

.dataTables_length label {
    margin: 0px !important;
}

.dataTables_info {
    border-top: 1px solid #F1F1F1 !important;
}

.badge-dt {
    background-color: #E08283 !important;
}

.pagination-panel {
    display: inline-block !important;
    padding: 7px !important;
    padding-right: 17px !important;
    color: #1F64A0 !important;
}

/***
Responsive Flip Scroll Tables
***/
.flip-scroll table {
    width: 100%;
}

@media only screen and (max-width: 768px) {
    /* 768px */
    .flip-scroll .flip-content:after {
        visibility: hidden;
        display: block;
        font-size: 0;
        content: " ";
        clear: both;
        height: 0;
    }
    .flip-scroll * html .flip-content {
        zoom: 1;
    }
    .flip-scroll *:first-child + html .flip-content {
        zoom: 1;
    }
    .flip-scroll table {
        width: 100%;
        border-collapse: collapse;
        border-spacing: 0;
    }
    .flip-scroll th,
    .flip-scroll td {
        margin: 0;
        vertical-align: top;
    }
    .flip-scroll th {
        text-align: left;
        border: 0 !important;
        border-bottom: 1px solid #ddd !important;
        border-right: 1px solid #ddd !important;
        font-size: 13px !important;
        padding: 5px;
        width: auto !important;
    }
    .flip-scroll table {
        display: block;
        position: relative;
        width: 100%;
    }
    .flip-scroll thead {
        display: block;
        float: left;
    }
    .flip-scroll tbody {
        display: block;
        width: auto;
        position: relative;
        overflow-x: auto;
        white-space: nowrap;
    }
    .flip-scroll .flip-content tbody tr td {
        font-size: 13px;
        line-height: 1.483;
    }
    .flip-scroll .table-bordered.flip-content tbody tr td {
        font-size: 13px;
        line-height: 1.43;
    }
    .flip-scroll thead tr {
        display: block;
    }
    .flip-scroll th {
        display: block;
        text-align: right;
    }
    .flip-scroll tbody tr {
        display: inline-block;
        vertical-align: top;
        margin-left: -5px;
    }
    .flip-scroll td {
        display: block;
        min-height: 1.25em;
        text-align: left;
        border-top: 0 !important;
        border-left: 0 !important;
        border-right: 0 !important;
    }
    /* sort out borders */
    .flip-scroll th {
        border-bottom: 0;
        border-left: 0;
    }
    .flip-scroll td {
        border-left: 0;
        border-right: 0;
        border-bottom: 0;
    }
    .flip-scroll tbody tr {
        border-left: 1px solid #ddd;
    }
    .flip-scroll th:last-child,
    .flip-scroll td:last-child {
        border-bottom: 1px solid #ddd;
    }
    /* Hide footer on small devices */
    .flip-scroll tfoot {
      display: none;
    }
}
