.article-title {
  border-bottom: 1px solid #ababab;
  padding-bottom: 4px;
}

.article-details {
  margin-top: 10px;
}

.article-content {
  margin-top: 20px;
  margin-bottom: 40px;
}

.article-created, .article-author, .article-modified, .article-hits {
  font-size: 0.8em;
}

.article-intro {

}

hr#system-readmore {
  display: none;
}
